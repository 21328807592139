/**
 * @class: Footer
 * @description: purpose of this component is to visualize application footer with navigation
 * @author: Nawod Madhuvantha
 */

import { ContactData, NavList } from "../../constants/header";
import { NavItem } from "../../models/header";
import { FaCopyright } from "react-icons/fa";
import "./style.scss";
import { Link } from "react-scroll";
import { BrowserRouter } from "react-router-dom";
import { SocialLinks } from "../../constants/links";
import LOGO from "../../../assests/images/ctma_logo.png";

const Footer = () => {
    const navList = NavList.list;

    return (
        <BrowserRouter>
            <nav className="footer-wrapper">
                <div className="main-container">
                    <div className="inside-container">
                        <div className="contact-row">
                            {ContactData.contact.map((item: any, index) => {
                                return (
                                    <a
                                        className="contact-card"
                                        key={index}
                                        href={item.action}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div className="card-logo">
                                            <img
                                                src={item.icon}
                                                alt=""
                                            />
                                        </div>
                                        <div className="contact-details">
                                            <div className="card-title regular-font">
                                                {item.title}
                                            </div>
                                            <div className="card-content regular-font-bold">
                                                {item.content}
                                            </div>
                                        </div>
                                    </a>
                                );
                            })}
                        </div>
                        <div className="footer-content">
                            <div className="footer-about">
                                <div className="logo">
                                    <img
                                        src={LOGO}
                                        alt=""
                                    />
                                </div>
                                <div className="footer-desc default-font">
                                    Founded in 2021 The Clinical Travel Medicine
                                    Association of Sri Lanka is the governing
                                    body for regulation and medical education of
                                    travel realated medical issues in the
                                    country.It is a dynamic and forward-thinking
                                    organization dedicated to advancing the
                                    field of travel medicine in the country. The
                                    Association has been registered under the
                                    Societies Ordinance in Sri Lanka bearing
                                    registration number S/0022624 following a
                                    gazette notification under the same
                                    ordinance.
                                </div>
                                <div className="social-icons">
                                    {SocialLinks.links.map((item, index) => {
                                        return (
                                            <a
                                                href={item.link}
                                                target="_blank"
                                                rel="noreferrer"
                                                key={index}
                                                className="icon"
                                            >
                                                {item.icon}
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="footer-navigation">
                                <div className="nav-title">Navigation</div>
                                {navList.map((item: NavItem, index) => {
                                    return (
                                        <Link
                                            to={item.route}
                                            spy={true}
                                            offset={-150}
                                            smooth={true}
                                            duration={500}
                                            delay={500}
                                            key={item.route}
                                            className="footer-nav regular-font"
                                        >
                                            {item.name}
                                        </Link>
                                    );
                                })}
                            </div>
                            <div className="footer-logo-container">
                                <div className="footer-logo">
                                    <img
                                        src={LOGO}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="copyrights-row">
                            <div className="web-name default-font ">
                                Clinical Travel Medicine Association
                            </div>
                            <div className="copyrights default-font ">
                                <FaCopyright className="copy-icon default-font " />
                                Copyright 2024,{" "}
                                <a
                                    href="https://www.nawodmadhuwantha.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Nawod Madhuwantha
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </BrowserRouter>
    );
};

export default Footer;
